import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const DiscountForCancerTreatments = () => {
  return (
    <>
      <Helmet>
        <title>
          Discounts for Cancer Patients - Investigations, Surgeries, Treatment
        </title>
        <meta
          name="description"
          content="copewithcancer has made arrangements for discounts for cancer patients diagnostics, treatments, surgeries, supplements &amp; Wigs."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/investigations-surgeries"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Discounts for Cancer Patients - Investigations, Surgeries, Treatment"
        />
        <meta
          property="og:description"
          content="copewithcancer has made arrangements for discounts for cancer patients diagnostics, treatments, surgeries, supplements &amp; Wigs."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/investigations-surgeries"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="our-causes" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="copewithcancer has made arrangements for discounts for cancer patients diagnostics, treatments, surgeries, supplements &amp; Wigs."
        />
        <meta
          name="twitter:title"
          content="Discounts for Cancer Patients - Investigations, Surgeries, Treatment"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-16T06:21:40+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew1"> Discounts on Diagnostics for
            <br />
            Cancer Patients</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="What is Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <h1 className="bannertitle displayNone">
            Discounts on Diagnostics for
            <br />
            Cancer Patients
          </h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone">
                Discounts on Diagnostics for Cancer Patients
              </h1>
              <h3 className="Bold28 mb20">
                <b>Discount for Cancer Treatment</b>
              </h3>
              <p>
                The patients and the caregivers bear the brunt of cancer
                treatment in a very big way with a whole lot of families pushed
                into financial drudgery due to treatment cost.
                <br />
                We are partnered with centers that offer{" "}
                <span className="Bold14">
                  discounted Chemotherapy, Radiation and Surgeries. Please
                  contact us for your treatment requirements{" "}
                  <span>
                    <Link
                      to="/contact-us"
                      className="urlLinkRgular"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here
                    </Link>
                  </span>
                </span>
                <br />
                <span id="more-8163"></span>
              </p>
              {/* <p className="Bold14">For Patients: </p> */}
              {/* <div dir="auto">
                Copewithcancer - Madat Trust provides partial financial
                assistance to patients taking treatment for Cancer in Government
                / municipal hospital and in General Ward of any Charitable
                hospital in Mumbai, where the hospital / doctor also provides
                substantial discount for the treatment (min. 30%)
              </div>
              If yes, Please email us the following documents at{" "}
              <span>
                <Link
                  className="urlLinkRgular"
                  to="mailto:support@copewithcancer.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@copewithcancer.org
                </Link>
              </span> */}
              {/* <ul className="listContentRegular mt20 mb20">
                <li>
                  Cost Estimate from the doctor / hospital indicating the
                  discount offered to the patient
                </li>
                <li>Patient Registration paper at the hospital</li>
                <li>Treatment plan</li>
                <li>Self-Application with a photo</li>
                <li>Ration Card</li>
                <li>Income Certificate</li>
                <li>ID Proof – Aadhar Card / Pan Card</li>
                <li>Xerox of Bank Statement</li>
                <li>Latest electricity Bill</li>
              </ul> */}
              {/* <p className="Bold14">Doctors and Laboratory Owners:</p>
              <p>
                Please contact us to add your name to our list of providers.
                This could be your Corporate Social Responsibility initiative.
                Providers who would like to offer their services at discounted
                could send us an email at{" "}
                <Link
                  to="mailto:anand@copewithcancer.org"
                  className="urlLinkRgular"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  anand@copewithcancer.org
                </Link>
              </p> */}
              <br/>
              <br/>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountForCancerTreatments;
