import { ErrorMessage, Field } from "formik";
const CommonInputs = () => {
  return (
    <>
      <div class="form-group">
        <label className="required">Full Name</label>
        <Field
          className="form-control"
          type="text"
          name="name"
          placeholder="Enter Your Full Name"
        />
        <ErrorMessage name="name" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">Email ID</label>
        <Field
          type="text"
          name="email"
          className="form-control"
          placeholder="Enter Your Email ID"
        />
        <ErrorMessage name="email" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">Contact Number</label>
        <Field
          type="text"
          name="contact"
          className="form-control"
          placeholder="Enter Your Contact Number"
        />
        <ErrorMessage name="contact" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">Country</label>
        <Field
          name="country"
          disabled
          className="form-control"
          placeholder="Enter Your Country Name"
        />
        <ErrorMessage name="country" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">State / Province / Region</label>
        <Field
          className="form-control"
          type="text"
          name="state"
          placeholder="Enter Your State / Province or Region"
        />
        <ErrorMessage name="state" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">City</label>
        <Field
          className="form-control"
          type="text"
          name="city"
          placeholder="Enter Your City Name"
        />
        <ErrorMessage name="city" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">Locality</label>
        <Field
          type="text"
          name="locality"
          className="form-control"
          placeholder="Enter Your Locality"
        />
        <ErrorMessage name="locality" component="div" className="text-danger" />
      </div>
      <div class="form-group">
        <label className="required">Postal / Zip Code</label>
        <Field
          className="form-control"
          type="text"
          name="zip_code"
          placeholder="Enter Your Postal or Zip Code"
        />
        <ErrorMessage name="zip_code" component="div" className="text-danger" />
      </div>
    </>
  );
};
export default CommonInputs;
