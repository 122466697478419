import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, Link, redirect, useNavigation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { formSubmitServices } from "../services/formSubmitService";
import ContactUsForm from "./Forms/ContactUsForm";
import HairDonationInquiry from "./Forms/HairDonationInquiry"
import OncologyTreatmentsForm from "./Forms/OncologyTreatmentsFrom";
import AntiCancerDrugsForm from "./Forms/AntiCancerDrugsForm";
import CancerScreening from "./Forms/CancerScreening";
import CtScanForm from "./Forms/CtScanForm";
import DigitalXRayForm from "./Forms/DigitalXRayForm";
import NuclearScanForm from "./Forms/NuclearScanForm";
import PathologyServicesForm from "./Forms/PathologyServices";
import MammoForm from "./Forms/MammoForm";
import PetCtScanForm from "./Forms/PetCtScanForm";
import MRIScanFrom from "./Forms/MRIScanFrom";
import Madatlogosmall from "../assets/img/contactUs/madat-logosmall.png";
import { toast } from "react-toastify";
import Loader from "./Common/Loader";

const Contact = () => {
  const { state } = useLocation();
  const navigation = useNavigation();
  const [selectedForm, setSelectedForm] = useState("contactUs");

  useEffect(() => {
    const formSelected = state;
    formSelected && setSelectedForm(formSelected);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Contact us - Cope with Cancer</title>
        <meta
          name="description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/contact-us"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact us - Cope with Cancer" />
        <meta
          property="og:description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/contact-us"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="og:image" content={Madatlogosmall} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta name="twitter:title" content="Contact us - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content={Madatlogosmall} />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-04-27T07:35:02+00:00" />
      </Helmet>

      <div className="topspace">
        {navigation.state === "loading" && <Loader />}
        <article>
          <div className="text-center bannerBgColor">
            <div className="contactus">
              <div className="bannertitleNew mediabannertitleNew">
                Contact Us
              </div>
            </div>
            {/* <img
              src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/contactus-banner-1.jpg"
              alt="Cope with Cancer"
              className="w-100 imgOpacityInner"
            ></img> */}
            <p class="bannertitle displayNone">Contact Us</p>
          </div>
          <div className="contentBox">
            <div className="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1\" className="Bold14">
                      Select Reasons
                    </label>
                    <select
                      className="form-control mb20 "
                      name="form"
                      id="form"
                      value={selectedForm}
                      onChange={(e) => {
                        setSelectedForm(e.target.value);
                      }}
                    >
                      <option value="contactUs">Contact Us</option>
                      <option value="hairDonation">Hair Donation Inquiry</option>
                      {/* <option value="freeWig">Free Wig</option> */}
                      <option value="oncology">Oncology Treatments</option>
                      <option value="antiCancerDrugs">Anti Cancer Drugs</option>
                      {/* <option value="cancerScreening">Cancer Screening</option> */}
                      <option value="ctScan">CT Scan</option>
                      {/* <option value="digitalXRay">Digital X-Ray</option> */}
                      <option value="nuclearScanForm">Nuclear Scans</option>
                      <option value="pathologyServices">
                        Pathology Services
                      </option>
                      <option value="mammo">
                        Mammography - Karo janch na aayegi aanch program{" "}
                      </option>
                      <option value="petCtScan">Pet CT</option>
                      <option value="mriScan">MRI Scan</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" id="labelBold">
                <div class="col-md-6">
                  {selectedForm === "contactUs" && <ContactUsForm />}
                  {selectedForm === "hairDonation" && <HairDonationInquiry />}
                  {selectedForm === "oncology" && <OncologyTreatmentsForm />}
                  {selectedForm === "antiCancerDrugs" && (
                    <AntiCancerDrugsForm />
                  )}
                  {/* {selectedForm === "cancerScreening" && <CancerScreening />} */}
                  {selectedForm === "ctScan" && <CtScanForm />}
                  {/* {selectedForm === "digitalXRay" && <DigitalXRayForm />} */}
                  {selectedForm === "nuclearScanForm" && <NuclearScanForm />}
                  {selectedForm === "pathologyServices" && (
                    <PathologyServicesForm />
                  )}
                  {selectedForm === "mammo" && <MammoForm />}
                  {selectedForm === "petCtScan" && <PetCtScanForm />}
                  {selectedForm === "mriScan" && <MRIScanFrom />}
                </div>
                <div className="col-md-6">
                  <div className="contactInfoBox">
                    <p>
                      {" "}
                      <a
                        href={Madatlogosmall}
                        rel="PrettyPhoto[8219]"
                        target="blank"
                      >
                        <img src={Madatlogosmall} alt="madat-logosmall"></img>
                      </a>
                    </p>
                    <p className="Bold18 mb10">Madat Charitable Trust</p>
                    <p>
                      We are unable to take voice calls. Please send SMS /
                      WhatsApp your name, email & requirement. We will reply
                      asap. Thank you for your patience.
                    </p>
                    <hr />
                    <p>
                      For more information on Hair/Wig Donation,{" "}
                      <Link
                        to={"/hair-donation"}
                        onClick={() => {}}
                        className="urlLinkRgular"
                      >
                        click here
                      </Link>{" "}
                      {/* <a
                        href="https://www.copewithcancer.org/hair-donation"
                        className="urlLinkRgular"
                        target="blank"
                      >
                        click here
                      </a>{" "} */}
                      or send an email to{" "}
                      <a
                        href="mailto:support@copewithcancer.org"
                        className="urlLinkRgular"
                      >
                        support@copewithcancer.org
                      </a>
                    </p>
                    <hr />
                    <p>
                      <div className="Bold14 mb10">
                        For all other queries, i.e., Financial assistance,
                        Treatment options, Financial Donations, please write to
                        us at
                      </div>
                      <a
                        href="mailto:urvija@copewithcancer.org"
                        className="urlLinkRgular"
                      >
                        urvija@copewithcancer.org
                      </a>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <div className="Bold14 mb10">WhatsApp / SMS no.:</div>
                      <i class="fa-brands fa-whatsapp mr5 whatsAppIcon"></i>{" "}
                      +91-99877 79639
                    </p>
                    <hr />
                    <div className="Bold14">
                      Working hours :
                      <span className="Regular14">
                        {" "}
                        Monday-Saturday 10.00 am - 06.00 pm
                      </span>
                    </div>
                    <div className="Bold14 mb15">
                      Closed on Sundays & Public Holidays
                    </div>
                    <hr />

                    <p>
                      {" "}
                      <div className="Bold14 mb10">Correspondence Address:</div>
                      Mangal Anand Hospital, 2’nd Floor, 48, Swastik Park,
                      Sion-Trombay Road, Chembur, Mumbai, 400071. India
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <div className="Bold14 mb10">Registered Address:</div>
                      64 A Jaldarshan, Napean Sea Road, Mumbai - 400036, INDIA.
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <div className="Bold14 mb10">
                        Need Help ? Have A Question ?
                      </div>
                      <Link
                        to={"/blog"}
                        onClick={() => {}}
                        className="urlLinkRgular"
                      >
                        Checkout Our Information Section
                      </Link>{" "}
                      {/* <a
                        href="https://www.copewithcancer.org/blog/"
                        rel="noopener noreferrer"
                        className="urlLinkRgular"
                        target="blank"
                      >
                        Checkout Our Information Section
                      </a>{" "} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Contact;

export const action = async ({ request, params }) => {
  const data = await request.formData();
  const title = data.get("title");
  let payload = {};
  const common = {
    name: data.get("name"),
    email_id: data.get("email"),
    phone: data.get("contact"),
    country: data.get("country"),
    state: data.get("state"),
    city: data.get("city"),
    locality: data.get("locality"),
    zip_code: data.get("zip_code"),
  };
  switch (title) {
    case "Anti-Cancer Drugs":
      payload = {
        ...common,
        title: "Anti-Cancer Drugs",
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    // case "Register for Cancer Screening":
    //   payload = {
    //     title: "Register for Cancer Screening",
    //     name: data.get("name"),
    //     email_id: data.get("email"),
    //     phone: data.get("mobileNo"),
    //     list_of_test: data.get("selectedTest"),
    //     city: data.get("city"),
    //     comments: data.get("comments"),
    //   };
    //   break;
    case "Register for Contact Us":
      payload = {
        ...common,
        title: "Contact us",
        form_subject: data.get("subject"),
        message: data.get("yourMessage"),
      };
      break;
    case "Hair Donation":
      payload = {
        ...common,
        title: "Hair Donation",
        form_subject: data.get("subject"),
        message: data.get("yourMessage"),
      };
      break;
    case "Register for CT scan":
      payload = {
        ...common,
        title: "Register for CT scan",
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    case "Discounted PET CT Scan":
      payload = {
        ...common,
        title: "Discounted PET CT Scan",
        dob: data.get("dateOfBirth"),
        gender: data.get("gender"),
        hospital: data.get("hospital"),
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    // case "Register for Digital X-Ray":
    //   payload = {
    //     title: "Register for Digital X-Ray",
    //     name: data.get("name"),
    //     email_id: data.get("email"),
    //     phone: data.get("mobile"),
    //     x_ray_of: data.get("digitalXRay"),
    //     city: data.get("availableCity"),
    //   };
    //   break;
    // case "Register for Free Wigs":
    //   payload = {
    //     title: "Register for Free Wigs",
    //     name: data.get("name"),
    //     address: data.get("address"),
    //     street_address: data.get("streetAddress"),
    //     state: data.get("state"),
    //     zip_code: data.get("postalCode"),
    //     country: data.get("country"),
    //     email_id: data.get("email"),
    //     phone: data.get("contactNumber"),
    //     city: data.get("city"),
    //   };
    //   break;
    case "Register for Screening Mammography":
      payload = {
        ...common,
        title: "Register for Screening Mammography",
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    case "Register for MRI Scan":
      payload = {
        ...common,
        title: "Register for MRI Scan",
        hospital: data.get("hospital"),
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    case "Nuclear Scans":
      payload = {
        ...common,
        title: "Nuclear Scans",
        nuclear_scan_of: data.get("nuclearScans"),
        // available_city: data.get("availableCity"),
      };
      break;
    case "Register For Oncology Treatment":
      payload = {
        ...common,
        title: "Register For Oncology Treatment",
        details_treatment: data.get("treatmentDetails"),
      };
      break;
    case "Register for Pathology Services":
      payload = {
        ...common,
        title: "Register for Pathology Services",
        bloood_test_required: data.get("bloodTestsRequired"),
      };
      break;
    default:
  }
  console.log("payload: ", payload);

  try {
    await new Promise((resolve, reject) => {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6LdLyXopAAAAAD3PW_eHiJ0m5hk4TCO5glcFLdZ5", {
            action: "submit",
          })
          .then(function (token) {
            console.log(token);
            payload = { ...payload, token };
            console.log("payload: ", payload);

            formSubmitServices.submitForm(
              payload,
              (res) => {
                if (res.data.reasonCode === "400") {
                  reject("The request has timed out. Please try again.");
                }
                resolve(res);
              },
              (err) => {
                reject(err);
              }
            );
          });
      });
    });
  } catch (err) {
    toast.error(err, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return null;
  }
  if (
    title === "Anti-Cancer Drugs" ||
    title === "Register for Cancer Screening" ||
    // title === "Register for Contact Us" ||
    title === "Hair Donation" ||
    title === "Register for CT scan" ||
    title === "Discounted PET CT Scan" ||
    title === "Register for Digital X-Ray" ||
    // title === "Register for Free Wigs" ||
    title === "Register for Screening Mammography" ||
    title === "Register for MRI Scan" ||
    // title === "Register For Oncology Treatment" ||
    title === "Nuclear Scans" ||
    title === "Register for Pathology Services"
    // title === "Register for Pathology Services"
  ) {
    toast.success("Your information has been submitted successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return redirect("/thank-you");
  } else if (
    title === "Register for Contact Us" ||
    // title === "Register for Free Wigs" ||
    title === "Register For Oncology Treatment"
  ) {
    toast.success("Your information has been submitted successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } //else if (title === "Anti-Cancer Drugs") {
  //   toast.success(
  //     "Thanks you. Please take a print out of the form. Please note, the form is valid for two weeks only.",
  //     {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     }
  //   );
  //   return redirect("/print");
  // }
  return null;
};
