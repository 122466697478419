import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { aboutStaffServiceService } from "../../services/aboutStaffService";
import Loader from "../Common/Loader";

const AboutCommonComponent = () => {
  const { state } = useLocation();
  // const statePostId = useSelector((state) => state.staffDetail.staffId);
  // statePostId ? (setPostId(statePostId)):(setPostId(state));
  // alert(postId)
  const [staffData, setStaffData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    aboutStaffServiceService.getIndividualStaffDetails((res) => {
      setLoading(false);
      console.log(res);
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setStaffData(res.data.ResponseObject);
        }
      }
      // }, postId);
    }, state);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {staffData && staffData.details && staffData.details.metatitle ? (
        <Helmet>
          {/* <title>Dr. Anand Parihar - Cope With Cancer</title> */}
          {/* {console.log(staffData)} */}
          <title>{`${staffData.details.metatitle} - Cope With Cancer`}</title>

          <meta
            name="description"
            content={staffData.details.metaDescription}
          />
          <meta name="robots" content="noindex,nofollow,noodp" />
          <link
            rel="canonical"
            href={`https://www.copewithcancer.org/staff-page/${staffData.details.canonical}`}
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={`${staffData.details.metatitle} - Cope With Cancer`}
          />
          <meta
            property="og:description"
            content={staffData.details.metaDescription}
          />
          <meta
            property="og:url"
            content={`https://www.copewithcancer.org/staff-page/${staffData.details.canonical}`}
          />
          <meta property="og:site_name" content="Cope with Cancer" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/copewithcancer/"
          />
          <meta
            property="og:image"
            // content="https://www.copewithcancer.org/wp-content/uploads/2015/07/alp.jpg"
            content={staffData.details.imageUrl}
          />
          <meta property="og:image:width" content="276" />
          <meta property="og:image:height" content="328" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content={staffData.details.metaDescription}
          />
          <meta
            name="twitter:title"
            content={`${staffData.details.metatitle} - Cope With Cancer`}
          />
          <meta name="twitter:site" content="@copewithcancer" />
          <meta name="twitter:image" content={staffData.details.imageUrl} />
          <meta name="twitter:creator" content="@copewithcancer" />
          <meta
            property="DC.date.issued"
            content={staffData.details.dateIssued}
          />
        </Helmet>
      ) : undefined}
      {staffData && staffData.details && staffData.details.title ? (
        <div className="topspace">
          <div className="contentBox">
            <div class="container sd-single-staff-page">
              <div class="row">
                <div class="col-md-8">
                  {/* <h2 class="heading1">Dr. Anand Parihar</h2> */}
                  <h2 class="Bold28">{staffData.details.title}</h2>
                  <h4 className="Regular14 mb10">
                    {/* <strong>Managing Trustee</strong> */}
                    {staffData.landingPage.designation}
                  </h4>
                  <h1></h1>
                  {/* {(staffData.details.description || []).map((item, index) => ( */}
                  {staffData.details.description &&
                  staffData.details.description != "" ? (
                    <ul id="nav">
                      {(staffData.details.description || []).map(
                        (item, index) => (
                          <li key={index}>{item}</li>
                        )
                      )}
                    </ul>
                  ) : null}
                  {/* // <ul id="nav">
                    //   {(staffData.details.description || []).map((item, index) => (
                    //   <li key={index}>{item}</li> 
                    //   ))} 
                    // </ul> */}
                  {/* ))} */}
                  {staffData?.details?.email && (
                    <div className="mb20">
                      <span class="sd-email">
                        {" "}
                        <Link
                          className="urlLinkRgular"
                          to={`mailto:${staffData.details.email}`}
                          title="E-Mail"
                        >
                          <i class="fa-solid fa-envelope mr5"></i>{" "}
                          {staffData.details.email}
                        </Link>
                      </span>
                    </div>
                  )}
                  <ul class="socialIcons mediaMB20">
                    {staffData?.details?.facebook && (
                      <li className="br">
                        <Link
                          to={staffData.details.facebook}
                          title="Facebook"
                          disabled={!staffData.details.facebook}
                          className="btn"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                      </li>
                    )}
                    {staffData?.details?.twitter && (
                      <li className="br">
                        {/* <Link to="https://twitter.com/#" title="Twitter"> */}
                        <Link to={staffData.details.twitter} title="Twitter">
                          <div class="twitterIcon cBtn"></div>
                        </Link>
                      </li>
                    )}
                    {staffData?.details?.linkedIn && (
                      <li>
                        <Link
                          to={staffData.details.linkedIn}
                          title="Linked In"
                          className="btn"
                        >
                          <i class="fa-brands fa-linkedin-in"></i>
                        </Link>
                      </li>
                    )}
                    {/* <li className="br">
                      <Link to={staffData.details.googlePlus} title="Google Plus" className="btn">
                        <i class="fa-brands fa-google-plus-g"></i>
                      </Link>
                    </li>
                    <li>

                      <Link to={staffData.details.skype} title="Skype" className="btn">
                        <i class="fa-brands fa-skype"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div class="col-md-4 sd-staff-single-img">
                  <figure>
                    {/* <img src={alp} alt="alp"></img> */}
                    <img
                      src={staffData.details.imageUrl}
                      alt={staffData.details.title}
                    ></img>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default AboutCommonComponent;
