import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const DigitalXray = () => {
  return (
    <div>
      <Helmet>
        <title>Discounted X-Ray/ Procedures - Cope With Cancer</title>

        <meta name="description" content="Madat Trust / Cope With Cancer has made a special arrangement medical service providers for discounts on investigations.Register for discounted X-Ray.." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/digital-x-ray-procedures" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Discounted X-Ray/ Procedures - Cope With Cancer" />
        <meta property="og:description" content="Madat Trust / Cope With Cancer has made a special arrangement medical service providers for discounts on investigations.Register for discounted X-Ray.." />
        <meta property="og:url" content="https://www.copewithcancer.org/digital-x-ray-procedures" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Madat Trust / Cope With Cancer has made a special arrangement medical service providers for discounts on investigations.Register for discounted X-Ray.." />
        <meta name="twitter:title" content="Discounted X-Ray/ Procedures - Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T10:56:34+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew84">Digital X-Ray / Procedures</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="What is Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <p class="bannertitle displayNone">Digital X-Ray / Procedures</p>
        </div>
        <div className="contentBox">
          <div class="container">

            <article>
              <p className="displayNone">Digital X-Ray / Procedures</p>

              <p>
                <span className="Bold14">
                  Copewithcancer - Madat Trust can try to help you get lowered
                  &amp; discounted rate for investigation &amp; diagnostic tests
                  in Mumbai.
                  <br />
                  Request you to fill up the form below.
                  <br />
                </span>
              </p>
              <Link to="#" state="digitalXRay">
              {/* <Link to="/contact-us" state="digitalXRay"> */}
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Register for Digital X-Ray
                </h2>
              </Link>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalXray;
