import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigation, useSubmit } from "react-router-dom";
import CommonInputs from "./CommonInputs";

const CustomContactForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full name a required field")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email Id a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    contact: Yup.string()
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      )
      .required("Contact number is a required field"),
    state: Yup.string().required("State is a required field"),
    city: Yup.string().required("City is a required field"),
    locality: Yup.string().required("Locality is a required field"),
    zip_code: Yup.string().required("Postal or Zip Code is a required field").matches(
      /^\d{6}$/,
      "Postal code should be a 6-digit number"
    ),
    subject: Yup.string().required("Please fill in the required field."),
    yourMessage: Yup.string().required("Please fill in the required field."),
  });

  const handleSubmit = (values, { resetForm }) => {
    values.title = "Register for Contact Us";
    submit(values, { method: "POST" });
    // Reset the form after successful submission
    resetForm();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <p className="displayNone">Contact Us</p>
          <div id="midleft1">
            <div className="Bold28 mb20">Contact Us</div>
            <div className="mb20">
              <div>
                Please post your message & we shall reply to you as soon as
                possible.
              </div>
              <div>
                For Discounted Medical Services:{" "}
                <span className="Bold14">
                  Diagnostics, Treatment and Surgeries{" "}
                </span>
                <Link
                  to="/investigations-surgeries"
                  onClick={() => {}}
                  href=""
                  className="urlLinkRgular"
                >
                  Click Here
                </Link>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              state: "",
              zip_code: "",
              country: "India",
              name: "",
              email: "",
              contact: "",
              city: "",
              locality: "",
              subject: "",
              yourMessage: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="mb15">
                <div className="mb15">
                  Fields marked with an <span className="requiredColor">*</span>{" "}
                  are required
                </div>
                <CommonInputs />
                <div className="form-group">
                  <label className="required" htmlFor="subject">
                    Subject
                  </label>
                  <Field
                    className="form-control"
                    id="subject"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label className="required" htmlFor="yourMessage">
                    Your Message
                  </label>
                  <Field
                    className="form-control"
                    as="textarea"
                    id="yourMessage"
                    name="yourMessage"
                    placeholder="Your Message"
                  />
                  <ErrorMessage
                    name="yourMessage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <button className="btn btn-primary " type="submit">
                  {navigation.state === "submitting"
                    ? "Processing..."
                    : navigation.state === "loading"
                    ? "Saved!"
                    : "Submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CustomContactForm;
