import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigation, useSubmit } from "react-router-dom";
import CommonInputs from "./CommonInputs";

const PetCtScanForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    country: "India",
    state: "",
    city: "",
    locality: "",
    zip_code: "",
    dateOfBirth: "",
    gender: "Male",
    hospital: "",
    treatmentDetails: "",
  };

  const today = new Date();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full name a required field")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email Id a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    contact: Yup.string()
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      )
      .required("Contact number is a required field"),
    state: Yup.string().required("State is a required field"),
    city: Yup.string().required("City is a required field"),
    locality: Yup.string().required("Locality is a required field"),
    zip_code: Yup.string().required("Postal or Zip Code is a required field").matches(
      /^\d{6}$/,
      "Postal code should be a 6-digit number"
    ),
    dateOfBirth: Yup.string()
      .required("This is a required field")
      .test(
        "valid-date",
        "Date of birth should not be greater than today",
        function (value) {
          const selectedDate = new Date(value);
          return !isNaN(selectedDate.getTime()) && selectedDate <= today;
        }
      ),
    gender: Yup.string().required("This is a required field"),
    hospital: Yup.string().required("This is a required field"),
    treatmentDetails: Yup.string().required("Treatment Detail is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    values.title = "Discounted PET CT Scan";
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="mb15">
          <div className="row">
            <div className="col-md-12">
              <div id="midleft1">
                <div className="Bold28 mb20">Register for PET CT</div>
                {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
              </div>
              <div className="mb15">
                Fields marked with an <span className="requiredColor">*</span>{" "}
                are required
              </div>
              <CommonInputs />
              <div class="form-group">
                <label className="required">Date Of Birth : (DD/MM/YYYY)</label>
                <Field
                  type="date"
                  name="dateOfBirth"
                  className="form-control"
                />
                <ErrorMessage
                  name="dateOfBirth"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div class="form-group">
                <label className="required">Gender</label>
                <div>
                  <div class="form-check form-check-inline">
                    <Field
                      class="form-check-input"
                      type="radio"
                      name="gender"
                      value="Male"
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      {" "}
                      Male
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <Field
                      class="form-check-input"
                      type="radio"
                      name="gender"
                      value="Female"
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      {" "}
                      Female
                    </label>
                  </div>
                </div>

                {/* <div>
                  <label>
                    <Field type="radio" name="gender" value="Male" />
                    Male
                  </label>
                  <label>
                    <Field type="radio" name="gender" value="Female" />
                    Female
                  </label>
                </div> */}
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div class="form-group">
                <label className="required">
                  Hospital where treatment is being taken
                </label>
                <Field type="text" name="hospital" className="form-control" />
                <ErrorMessage
                  name="hospital"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div class="form-group">
                <label className="required">
                  Details of Treatment Recommended
                </label>
                <Field
                  type="text"
                  name="treatmentDetails"
                  className="form-control"
                />
                <ErrorMessage
                  name="treatmentDetails"
                  component="div"
                  className="text-danger"
                />
              </div>
              {/* <div>
                <div>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div> */}
              <button
                class="btn btn-primary my-2 my-sm-0 Allbtn "
                type="submit"
                disabled={isSubmitting}
              >
                {navigation.state === "submitting"
                  ? "Processing..."
                  : navigation.state === "loading"
                  ? "Saved!"
                  : "Submit"}
              </button>
            </div>
            {/* <div className="col-md-6">
              <div className="contactInfoBox">For All Queries, Write To Us</div>
            </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PetCtScanForm;
