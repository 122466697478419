import React from "react";

const Historyinabout = () => {
  return (
    <div>
      <div className="stepsbgImg">
        <div className="stepsBg">
          <div className="container">
            <div className="contentSection">
              <div className=" text-center mb30">
                <p className="titleWhite">History</p>
                <div className="dividerWhite"></div>
              </div>
              <div className="text-center mobileShowNone">
                <div className="stepGrayBg">
                  <ul
                    className="nav nav-tabs flexJustify "
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link triangle triangle-5 active "
                        id="2013-tab"
                        data-toggle="tab"
                        href="#2013"
                        role="tab"
                        aria-controls="2013"
                        aria-selected="true"
                      >
                        2013
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2014-tab"
                        data-toggle="tab"
                        href="#2014"
                        role="tab"
                        aria-controls="2014"
                        aria-selected="false"
                      >
                        2014
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2015-tab"
                        data-toggle="tab"
                        href="#2015"
                        role="tab"
                        aria-controls="2015"
                        aria-selected="false"
                      >
                        2015
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2016-tab"
                        data-toggle="tab"
                        href="#2016"
                        role="tab"
                        aria-controls="2016"
                        aria-selected="false"
                      >
                        2016
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2017-tab"
                        data-toggle="tab"
                        href="#2017"
                        role="tab"
                        aria-controls="2017"
                        aria-selected="false"
                      >
                        2017
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2018-tab"
                        data-toggle="tab"
                        href="#2018"
                        role="tab"
                        aria-controls="2018"
                        aria-selected="false"
                      >
                        2018
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2019-tab"
                        data-toggle="tab"
                        href="#2019"
                        role="tab"
                        aria-controls="2019"
                        aria-selected="false"
                      >
                        2019
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2020-tab"
                        data-toggle="tab"
                        href="#2020"
                        role="tab"
                        aria-controls="2020"
                        aria-selected="false"
                      >
                        2020
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2021-tab"
                        data-toggle="tab"
                        href="#2021"
                        role="tab"
                        aria-controls="2021"
                        aria-selected="false"
                      >
                        2021
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2022-tab"
                        data-toggle="tab"
                        href="#2022"
                        role="tab"
                        aria-controls="2022"
                        aria-selected="false"
                      >
                        2022
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2023-tab"
                        data-toggle="tab"
                        href="#2023"
                        role="tab"
                        aria-controls="2023"
                        aria-selected="false"
                      >
                        2023
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="2024-tab"
                        data-toggle="tab"
                        href="#2024"
                        role="tab"
                        aria-controls="2024"
                        aria-selected="false"
                      >
                        2024
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobileShowNone">
                <div className="tab-content p0 " id="myTabContent">
                  <div
                    className="tab-pane  fade show active stepContentBox"
                    id="2013"
                    role="tabpanel"
                    aria-labelledby="2013-tab"
                  >
                    <h3 className="BoldColor30">2013</h3>
                    <p>
                      In Jul 2013 Cope with Cancer website was initiated and
                      later in August Cope with Cancer website soft launched
                      with about 20+ pages. In the course of the year Cope with
                      Cancer updated to 120+ pages. More information on
                      resources & related topics for cancer patients and
                      caregivers added. We changed the website from static to
                      dynamic. In September 2013 itself we had 150000 hits on
                      the website - 7000 hits per month
                    </p>
                    <p>
                      In September we tied up with Helpline: iCALL for tele
                      counseling and later with Helpline: Aasra.{" "}
                    </p>
                    <p>
                      Nov 2013 we tied up with Midtown Parel for concessional
                      radiology investigations for TMH patients
                    </p>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2014"
                    role="tabpanel"
                    aria-labelledby="2014-tab"
                  >
                    <h3 className="BoldColor30">2014</h3>
                    <p>
                      At the rate of 9000 hits per month we had over 100,000
                      visitors assisted through cope with cancer website and by
                      December 350,000 patients and caregivers were assisted
                      through the website
                    </p>
                    <p>
                      2014 saw us run a number of awareness drives. Hair
                      donation awareness program was launched, we participated
                      in Lavasa Car Rally. We associated with Red FM Cochin for
                      hair donation drive in Kerala and subsequently a Hair
                      donation awareness program along with the Tuning Folks. We
                      tied up with R K wigs Pankaj Bhupatkar for wigs.
                    </p>
                    <p>
                      We initiated discounted mammography with Suburban
                      Diagnostics, Jankharia Imaging, Dr Aman Daftary’s Clinic,
                      Dr Ambrish Dalal’s clinic & Dr Gala’s clinic. Discounted
                      prosthesis and services offered on CopewithCancer website
                    </p>
                    <p>
                      Counselors Training program was launched in September
                      2014, where Dr. Nagesh Simha, a specialist in palliative
                      care gave a lecture on Psychosocial and spiritual issues
                      in palliative care- at TISS to the students of psychiatry.
                    </p>
                    <p>
                      In September, we started disbursing funds for
                      investigations and treatment for patients at Tata Hospital
                      Mumbai through MSW department.
                    </p>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2015"
                    role="tabpanel"
                    aria-labelledby="2015-tab"
                  >
                    <h3 className="BoldColor30">2015</h3>
                    <p>
                      In January we started disbursing free wigs to women
                      patients in Tata Hospital parel. In March we had a wig
                      donation program in TMH & felicitation of Madat Trust for
                      activities done at TMH. Again we partnered with Red FM
                      Cochin hair donation drive in Kerala
                    </p>
                    <p>
                      In June cope with cancer partnered with Association of
                      Medical Consultants for spreading awareness about cancer.
                      Superb performance by Sivamani and Runa Sivamani to
                      celebrate Doctors Day at In Orbit mall Malad.{" "}
                    </p>
                    <p>
                      In July educational & financial aid given to cancer
                      victors’ (computer and funds)
                    </p>
                    <p>
                      In July as a part of Doctors Day program, we presented the
                      activities the association with AMC for the year 2015 -
                      16.
                    </p>
                    <p>
                      In September Helpdesk was started in TMH Golden Jubilee
                      Building with volunteers to assist patients
                    </p>
                    <p>
                      By December 700,000 cancer patients and caregivers were
                      benefited through our online and telephonic counseling
                      service and our website
                    </p>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2016"
                    role="tabpanel"
                    aria-labelledby="2016-tab"
                  >
                    <h3 className="BoldColor30">2016</h3>
                    <p>
                      In January we started Helpdesk, wig donation, financial
                      assistance and post op counseling in ACTREC Tata,
                      Kharghar. We also attended counseling workshop organized
                      at Actrec
                    </p>
                    <p>
                      Awareness program for cope with cancer with the Tuning
                      Folks was organised
                    </p>
                    <p>
                      In March we started post operation counseling sessions
                      with breast OPD
                    </p>
                    <p>
                      In March we also participated in Hair donation drive and
                      awareness program at Jaslok hospital on the occasion of
                      Women’s Day
                    </p>
                    <p>
                      In April we started Pediatric ward counseling sessions and
                      student volunteers spent time with patients in pediatric
                      ward during their annual vacation. The volunteers started
                      manning the genera Helpline & Helpdesk at Tata Hospital
                      Parel
                    </p>
                    <p>
                      Come August the information on the cope with cancer
                      website was re-organised and updated. The site has been
                      spruced up with a with a new look and made mobile
                      friendly.
                    </p>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2017"
                    role="tabpanel"
                    aria-labelledby="2017-tab"
                  >
                    <h3 className="BoldColor30">2017</h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, weekly Pediatric ward counseling sessions
                        for pediatric ward patients and caregivers in TMH
                        continues with a very positive feedback from the
                        patients, caregivers and organizers.
                      </li>
                      <li>
                        Volunteers continue to hold counseling & physiotherapy
                        sessions for post-operative patients at breast OPD in
                        Tata Memorial Hospital and Actrec
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>

                      <li>
                        April 2017, volunteers attended CME workshop organized
                        at Sir H N Reliance Foundation Hospital to raise
                        awareness about physical as well as psychological
                        rehabilitation of the patients who are on cancer therapy
                        was discussed. One of the most significant part of
                        cancer therapy is Rehabilitation of the patient back to
                        his / her physical and mental status as it was pre
                        therapy.
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                    </ul>

                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 70%,
                        radiotherapy 14%, surgery 4%, & ICU support 12%
                      </li>
                      <li>
                        Financial assistance given to patients for discounted
                        radiotherapy to be done at Hiranandani Hospital.
                      </li>
                    </ul>
                    <p className="Bold16">Discounted Investigations</p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiated terms with various institutions to provide
                        discounted Radiotherapy and with onco-surgeons for
                        economically challenged patients outside Tata Hospital
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Donate about 50 wigs per month at TMH and online
                        requests
                      </li>
                    </ul>
                    <p className="Bold16">Patient comfort through Ola Cabs</p>
                    <ul className="listContentRegular">
                      <li>
                        Ola Cabs continue to provide 20% discount to Tata
                        Hospital patients to travel in comfortable & hygienic
                        environment since April 2017. More than 600 patients and
                        caregivers per month avail of this facility
                      </li>
                    </ul>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2018"
                    role="tabpanel"
                    aria-labelledby="2018-tab"
                  >
                    <h3 className="BoldColor30">2018</h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, weekly Pediatric ward counseling sessions
                        for pediatric ward patients and caregivers in TMH
                        continues with a very positive feedback from the
                        patients, caregivers and organizers.
                      </li>
                      <li>
                        Volunteers continue to hold counseling & physiotherapy
                        sessions for post-operative patients at breast OPD in
                        Tata Memorial Hospital and Actrec
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                      <li>
                        April 2017, volunteers attended CME workshop organized
                        at Sir H N Reliance Foundation Hospital to raise
                        awareness about physical as well as psychological
                        rehabilitation of the patients who are on cancer therapy
                        was discussed. While most doctors are focusing on
                        treatment of cancer and improving survival for patients,
                        there are a few other factors which are important for
                        patients, which do not seem to get focused on often. One
                        of the most significant part of cancer therapy is
                        Rehabilitation of the patient back to his / her physical
                        and mental status as it was pre therapy
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a className="urlLinkRgular" href="/">
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        August 2016 CWC website updated with more information
                        and a new look
                      </li>
                      <li>
                        Blogs, articles and information added on the website.
                      </li>
                    </ul>
                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 70%,
                        radiotherapy 14%, surgery 4%, & ICU support 12%
                      </li>
                      <li>
                        Financial assistance given to patients for discounted
                        radiotherapy to be done at Hiranandani Hospital.
                      </li>
                    </ul>
                    <p className="Bold16">Discounted Investigations</p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiating terms with various institutions to provide
                        discounted Radiotherapy for patients outside Tata
                        Hospital
                      </li>
                      <li>
                        Negotiating terms with various onco-surgeons to provide
                        discounted surgeries for economically challenged
                        patients.
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Tied up with another wigmaker as the demand for wigs is
                        constantly increasing.
                      </li>
                      <li>
                        Donate about 50 wigs per month at TMH and online
                        requests
                      </li>
                    </ul>
                    <p className="Bold16">Awareness Programs</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, The Tuning Folks once again dedicated a
                        musical evening to Madat trust for raising awareness
                        about cancer care.
                      </li>
                      <li>
                        September 2017 Project 6Kx6K launched to make more
                        people aware and involved in cancer care.
                      </li>
                      <li>
                        October 2017 Aapollo Multimedia supported Madat Trust
                        activities to bring about an awareness through a musical
                        program.
                      </li>
                      <li>
                        November 2017, Sargam, The Musical Medicos, supported
                        Madat Trust activities to bring about an awareness
                        through a musical program.
                      </li>
                      <li>
                        December 2017, photo booth organized at Christmas Party
                        for children at TMH. Small attempt to make sure that
                        they took with them some pleasant memories of Tata as
                        well. More than 200 children with their caregivers were
                        given a photograph clicked with props.
                      </li>
                      <li>
                        February 2018 Aapollo Multimedia supported Madat Trust
                        activities to bring about an awareness through a musical
                        program. A brief AV of Madat was shown to the audience.
                      </li>
                    </ul>
                    <p className="Bold16">Patient comfort through Ola Cabs</p>
                    <p>
                      Ola Cabs continue to provide 20% discount to Tata Hospital
                      patients to travel in comfortable & hygienic environment
                      since April 2017. More than 600 patients and caregivers
                      per month avail of this facility.
                    </p>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers{" "}
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers continue to access the
                        counseling helplines iCALL & Aasra for psycho social
                        solutions to their problems.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2019"
                    role="tabpanel"
                    aria-labelledby="2019-tab"
                  >
                    <h3 className="BoldColor30">
                      <b>2019</b>
                    </h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        Weekly post-operative counseling & physiotherapy
                        sessions for breast OPD patients in TMH continue with
                        very positive feedback from the patients & caregivers in
                        Tata Memorial Hospital Parel and Actrec
                      </li>
                      <li>
                        Volunteers continue to hold counseling sessions for
                        pediatric, gynec & thoracic ward patients
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1593148384282000&usg=AFQjCNEF03QUHH1ywHhKte2W5rJKVzI7kA"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        CWC website updated with more information and a new look
                      </li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website
                      </li>
                    </ul>
                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 30%,
                        radiotherapy 30%, surgery 22%, Investigations 8% &
                        supportive care 10%
                      </li>
                      <li>
                        Financial assistance continues for patients at Homi
                        Bhabha Cancer Hospital Varanasi
                      </li>
                      <li>
                        Financial assistance given to the economically
                        challenged patients for discounted chemotherapy,
                        radiotherapy & surgery to be done at Dr L H Hiranandani
                        Hospital, H N Reliance Hospital, MPCT Hospital, Asian
                        cancer Institute, Masina Hospital, DNB Onco care, Holy
                        spirit Hospital etc
                      </li>
                      <li>
                        Tied up with a group of onco-surgeons called Mumbai
                        Cancer Care - MCC who provide low cost packages for
                        surgery. Madat Trust partially funds the needy patients.
                      </li>
                      <li>
                        Negotiating terms with various Onco-Surgeons to provide
                        discounted surgeries for economically challenged
                        patients.
                      </li>
                      <li>
                        Financial assistance with subsidy offered to TMH
                        patients for diagnostic tests, the patients paying only
                        what they would have paid at TMH
                      </li>
                    </ul>
                    <p className="Bold16">
                      Discounted Investigations / treatment
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiating terms with various institutions to provide
                        discounted Radiation therapy for patients outside Tata
                        Hospital
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Donate appx 50 wigs per month to patients undergoing
                        chemotherapy at Tata Hospital Mumbai, CMC Vellore, CIHSR
                        Hospital at Dimapur Nagaland, Padhar Hospital Betul MP
                        and to online requests from all over India.
                      </li>
                      <li>
                        Hair donations received from individual donors,
                        institutions & parlours from all over the country &
                        abroad. More than 10000 men, women & children have
                        donated as yet.
                      </li>
                      <li>
                        February 2020, participated in Hairey’s Angel, the hair
                        donation drive organised by Jaslok Hospital at kala
                        Ghoda festival
                      </li>
                    </ul>
                    <p className="Bold16">
                      Awareness Programs
                      <br />
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Promotion of Project 6Kx6K continues through various
                        social media like Facebook, Twitter, Instagram etc to
                        make more people aware and involved in cancer care.
                      </li>
                      <li>
                        April 2019 Dr Anand Parihar participated in a panel
                        discussion organised by Sanjeevani Trust for
                        psycho-social support in cancer care which was attended
                        by 700+ cancer patients, caregivers & guests.
                      </li>
                      <li>
                        May, August, November 2019 Aadvita Multimedia supported
                        Madat Trust activities to bring about awareness through
                        a musical program.
                      </li>
                      <li>
                        August 2019, The Tuning Folks once again dedicated a
                        musical evening to Madat trust for raising awareness
                        about cancer care.
                      </li>
                      <li>
                        In September, volunteers attended the first of the three
                        module organizational development program “Saksham”
                        organised by S P Jain Institute of Management & Research
                        through their Centre for Education in the Social Sector
                        (CEdSS). This unique program was for patient support
                        groups & patient advocates to bring sustainable impact
                        towards patient centric care & to understand the
                        increasing need of professionalism and sound process
                        orientation required to manage and lead organizations.
                      </li>
                      <li>
                        October 2019, formed a patient representative committee
                        at Actrec with the directors & NGOs for patient benefit
                      </li>
                      <li>
                        December 2019 participated in NGO workshop and the panel
                        discussion at Lung Conference
                      </li>
                      <li>
                        December 2019 attended the launch program of the Three
                        Months Certification Course in Palliative Care at
                        Mumbai. Course director Dr Naveen Salins (Professor and
                        Head of the Department, Palliative Medicine and
                        Supportive Care, Kasturba Hospital).
                      </li>
                      <li>
                        December 2019, tied up with Happydemics, the company
                        founded by the singer Shaan to promote upcoming talent.
                        Every month one singer would perform at Actrec for
                        patients, caregivers & doctors
                      </li>
                    </ul>
                    <ul className="listContentRegular">
                      <li>
                        January 2020, attended first Testicular Cancer Survivors
                        meet at Tata Memorial Hospital in association with
                        Gunvati Jagan Nath Kapoor Foundation for understanding
                        testicular patients’ counseling
                      </li>
                      <li>
                        Feb 2020 Aadvita Multimedia once again supported Madat
                        Trust activities to bring about awareness through a
                        musical program.
                      </li>
                      <li>
                        In March 2020, begun a campaign ShootIt - demonstrating
                        the method of breast self examination on social media
                        sites. We requested the relevant doctors to make a one
                        minute clip informing the viewers about the importance
                        of BSE.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers call our counsellors for
                        psycho social solutions to their problems
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2020"
                    role="tabpanel"
                    aria-labelledby="2020-tab"
                  >
                    <h3 className="BoldColor30">2020</h3>
                    <p className="Bold16">
                      During the current Covid19 pandemic, Madat Charitable
                      Trust has contributed to the cause of humanity and has
                      donned the role of the 'corona warrior' since March 2020.
                    </p>
                    <p className="Bold16">Financial Assistance to patients-</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continued at TMH and Actrec.
                        Average proportion spent is chemotherapy 30%, radiation
                        therapy 30%, surgery 22%, Investigations 8% & supportive
                        care 10%
                      </li>
                      <li>
                        Financial assistance continued for patients at Homi
                        Bhabha Cancer Hospital Varanasi
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy.
                      </li>
                      <li>
                        Financial assistance given to the economically
                        challenged patients for discounted chemotherapy,
                        radiotherapy & surgery to be done at Dr L H Hiranandani
                        Hospital, H N Reliance Hospital, Reliance Hospital Navi
                        Mumbai, Masina Hospital, Conwest Hospital, HCG ICS
                        Hospital, Super Specialty Surgery Cancer hospital, Holy
                        spirit Hospital etc
                      </li>
                    </ul>
                    <p className="Bold16">Patient Assistance in TMH-</p>
                    <ul className="listContentRegular">
                      <li>
                        From March 2020 copewithcancer-Madat Trust helpdesk was
                        un-operational & the volunteer activities at TMH were
                        not possible.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website
                      </li>
                      <li>CWC website updated with more information</li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website.
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        From March-Dec 2020, we had stopped accepting hair
                        donations.{" "}
                      </li>
                      <li>
                        Number of wigs donated had stopped from March –Dec 2020.{" "}
                      </li>
                    </ul>
                    <p className="Bold16">Awareness Programs</p>
                    <ul className="listContentRegular">
                      <li>
                        Relevant talks by oncologist & onco surgeons from TMH
                        were shared on copewithcancer Facebook page
                      </li>
                      <li>
                        Promotion of Project 6Kx6K continues through various
                        social media to make more people aware and involved in
                        cancer care.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers access the website for{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        E-counseling & connect with our counselors for
                        tele-counseling for non-medical, psycho-social solutions
                        to their problems.
                      </li>
                      <li>
                        Our counselors provide free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>
                  <div
                    className="tab-pane stepContentBox"
                    id="2021"
                    role="tabpanel"
                    aria-labelledby="2021-tab"
                  >
                    <h3 className="BoldColor30">2021</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                        <a name="m_-8658947324293647620__Hlk102838162"></a>
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam & Malabar
                        Cancer Centre in Kerala & in Mumbai at Dr LH Hiranandani
                        Hospital, HN Reliance Hospital, Reliance Hospital Navi
                        Mumbai, Masina Hospital, Saboo Siddiqui Hospital, Ved
                        Hospital, SRV Hospital, Ashirwad Hospital HCG group of
                        Hospitals, Super Specialty Surgery Cancer, MOC Hospital,
                        Oncura Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered   tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Proportion of spend in 2021-22
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">38%</td>
                          <td width="161">70%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">18%</td>
                          <td width="161">6%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0</td>
                          <td width="161">21%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">44%</td>
                          <td width="161">3%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 10,000</td>
                          <td width="161">₹ 27,000</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        From March 2020 due to the Covid19 pandemic, layout
                        changes & lack of table space, all the helpdesks in
                        general OPD area at TMH are non-operational.
                      </li>
                      <li>
                        {" "}
                        Post-operative counseling & physiotherapy sessions for
                        breast OPD patients in TMH continue with a very positive
                        feedback from the patients & caregivers in Tata Memorial
                        Hospital Parel.
                      </li>
                      <li>
                        Volunteers continue to counsel thoracic ward &
                        palliative patients one on one
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Restarted accepting hair donation from January 2021.Hair
                        donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10396 men, women & children have
                        donated hair this year. Hair donations received from
                        individual donors, institutions & parlours from all over
                        the country & abroad
                      </li>
                      <li>
                        Restarted couriering wigs to patients undergoing
                        chemotherapy at Tata Hospital Mumbai and to online
                        requests from all over India. 180 wigs were donated to
                        women patients undergoing chemotherapy at Tata Hospital
                        Mumbai. 174 wigs were couriered to women patients
                        undergoing chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website
                      </li>
                      <li>CWC website updated with more information</li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website.
                      </li>
                    </ul>
                    <div>
                      <p className="Bold16">
                        Counseling - Online & Telephonic Counseling to Patients
                        & Caregivers
                      </p>
                      <ul className="listContentRegular">
                        <li>
                          Cancer patients and caregivers accessed the website{" "}
                          <a
                            className="urlLinkRgular"
                            href="/"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                          >
                            copewithcancer.org
                          </a>{" "}
                          for E-counseling & connect with our counselors for
                          tele-counseling for non-medical, psycho-social
                          solutions to their problems.
                        </li>
                        <li>
                          Our counselors provided free psycho-social tele
                          counseling to breast, pediatric, gynec & thoracic ward
                          patients of Tata Hospital Mumbai & Actrec Kharghar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className="tab-pane stepContentBox"
                    id="2022"
                    role="tabpanel"
                    aria-labelledby="2022-tab"
                  >
                    <h3 className="BoldColor30">2022</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai F at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam, Deenanath
                        Mangeshkar Hospital Pune, Sane Guruji Rugnalaya Nashik &
                        Malabar Cancer Centre in Kerala & in Mumbai at Dr LH
                        Hiranandani Hospital, HN Reliance Hospital, Reliance
                        Hospital Navi Mumbai, SRV Hospital, HCG group of
                        Hospitals, Super Specialty Surgery Cancer, Oncura
                        Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered  tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Donation in 2022-23
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228" className="Bold16">
                            Total Amount Donated
                            <br />
                            <span className="Bold16">Rs 354,56,870</span>
                          </td>

                          <td width="198" className="Bold16">
                            ₹ 151,96,000
                          </td>
                          <td width="161" className="Bold16">
                            ₹ 202,60,870
                          </td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">33.8%</td>
                          <td width="161">59.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">32.6%</td>
                          <td width="161">8.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0.1%</td>
                          <td width="161">26.4%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">33.5%</td>
                          <td width="161">4.8%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 12032</td>
                          <td width="161">₹ 30652</td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10158 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        174 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        177 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic
                    </p>
                  </div>
                  {/* 2023 */}
                  <div
                    className="tab-pane stepContentBox"
                    id="2023"
                    role="tabpanel"
                    aria-labelledby="2023-tab"
                  >
                    <h3 className="BoldColor30">2023</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam, Deenanath
                        Mangeshkar Hospital Pune, Sane Guruji Rugnalaya Nashik &
                        Malabar Cancer Centre in Kerala & in Mumbai at Dr LH
                        Hiranandani Hospital, HN Reliance Hospital, Reliance
                        Hospital Navi Mumbai, SRV Hospital, HCG group of
                        Hospitals, Super Specialty Surgery Cancer, Oncura
                        Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered  tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Donation in 2022-23
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228" className="Bold16">
                            Total Amount Donated
                            <br />
                            <span className="Bold16">Rs 354,56,870</span>
                          </td>

                          <td width="198" className="Bold16">
                            ₹ 151,96,000
                          </td>
                          <td width="161" className="Bold16">
                            ₹ 202,60,870
                          </td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">33.8%</td>
                          <td width="161">59.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">32.6%</td>
                          <td width="161">8.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0.1%</td>
                          <td width="161">26.4%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">33.5%</td>
                          <td width="161">4.8%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 12032</td>
                          <td width="161">₹ 30652</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Patient Assistance in TMH - Activities suspended post
                      Covid pandemic
                    </p>
                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10158 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        174 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        177 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic Number of patients impacted & the
                      amount spent since inception.
                    </p>
                    <table className="table table-bordered  tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Year
                          </th>
                          <th scope="col" className="Bold16">
                            No. of pts
                          </th>
                          <th scope="col" className="Bold16">
                            Amt Spent in Lac ₹
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">2014-15</td>
                          <td width="20%">286</td>
                          <td width="20%">10 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2015-16</td>
                          <td width="20%">660</td>
                          <td width="20%">23.4 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2016-17</td>
                          <td width="20%">778</td>
                          <td width="20%">26.7 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2017-18</td>
                          <td width="20%">850</td>
                          <td width="20%">29.5 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2018-19</td>
                          <td width="20%">1292</td>
                          <td width="20%">71 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2019-20</td>
                          <td width="20%">1782</td>
                          <td width="20%">105.8 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2020-21</td>
                          <td width="20%">582</td>
                          <td width="20%">140.2 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2021-22</td>
                          <td width="20%">1466</td>
                          <td width="20%">260.5 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2022-23</td>
                          <td width="20%">1924</td>
                          <td width="20%">354.5 L</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* 2024 */}
                  <div
                    className="tab-pane stepContentBox"
                    id="2024"
                    role="tabpanel"
                    aria-labelledby="2024-tab"
                  >
                    <h3 className="BoldColor30">2024</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy, radiation therapy, investigations &
                        supportive care. Financial assistance given at Cacher
                        Cancer Hospital in Assam, Deenanath Mangeshkar Hospital
                        Pune, Shri Guruji Rugnalaya Nashik & Malabar Cancer
                        Centre in Kerala & at HN Reliance Hospital, Dr LH
                        Hiranandani Hospital, Reliance Hospital Navi Mumbai, SRV
                        Hospital, MAH hospital, HCG group of Hospitals,
                        Specialty Surgical Oncology Hospital, Indian Cancer
                        Treatment Centre, Sunrise Oncology, PCCC, Sunrise
                        Oncology Center etc in Mumbai.
                      </li>
                    </ul>
                    <table className="table table-bordered  tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16" colSpan="2">
                            Annual Report FY 2023-2024
                          </th>
                          <th scope="col" className="Bold16">
                            Patients
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228">Total Amount Donated</td>
                          <td width="198">₹ 3.95 cr</td>
                          <td width="161">1988</td>
                        </tr>
                        <tr>
                          <td width="228">Private Hospitals - 55%</td>
                          <td width="198">₹ 2.16 cr</td>
                          <td width="161">677</td>
                        </tr>
                        <tr>
                          <td width="228">TMH & Affiliated Hospitals 45%</td>
                          <td width="198">₹ 1.79 cr</td>
                          <td width="161">1311</td>
                        </tr>
                        <tr>
                          <td width="228"></td>
                          <td width="198"></td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">48.70%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Chemo therapy</td>
                          <td width="198">19.10%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Radiation Therapy </td>
                          <td width="198">15.10%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & Supportive Care</td>
                          <td width="198">17.10%</td>
                          <td width="161"></td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Patient Assistance in TMH - Activities suspended post
                      Covid pandemic
                    </p>
                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 7289 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        192 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        152 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Breast Cancer Treatment Project - " Karo Janch Na Ayegi
                      Aanch"
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        We are providing free breast cancer screening and
                        discounted investigations and treatment. Mastectomy with
                        axillary clearance is being done at INR 50,000 at
                        various reputed hospitals in Mumbai with the cooperation
                        of senior breast surgeons
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic
                    </p>
                    <table className="table table-bordered  tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16" colSpan="3">
                            TOTAL NUMBER OF PATIENTS IMPACTED & AMOUNT
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="Bold16">
                            Year
                          </th>
                          <th scope="col" className="Bold16">
                            Amt Spent
                          </th>
                          <th scope="col" className="Bold16">
                            Patients
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">2014-15</td>
                          <td width="20%">10 L</td>
                          <td width="20%">286</td>
                        </tr>
                        <tr>
                          <td width="20%">2015-16</td>
                          <td width="20%">23.4 L</td>
                          <td width="20%">660</td>
                        </tr>
                        <tr>
                          <td width="20%">2016-17</td>
                          <td width="20%">26.7 L</td>
                          <td width="20%">778</td>
                        </tr>
                        <tr>
                          <td width="20%">2017-18</td>
                          <td width="20%">29.5 L</td>
                          <td width="20%">850</td>
                        </tr>
                        <tr>
                          <td width="20%">2018-19</td>
                          <td width="20%">71 L</td>
                          <td width="20%">1292</td>
                        </tr>
                        <tr>
                          <td width="20%">2019-20</td>
                          <td width="20%">1.06 Cr</td>
                          <td width="20%">1782</td>
                        </tr>
                        <tr>
                          <td width="20%">2020-21</td>
                          <td width="20%">1.40 Cr</td>
                          <td width="20%">582</td>
                        </tr>
                        <tr>
                          <td width="20%">2021-22</td>
                          <td width="20%">2.61 Cr</td>
                          <td width="20%">1466</td>
                        </tr>
                        <tr>
                          <td width="20%">2022-23</td>
                          <td width="20%">3.55 Cr</td>
                          <td width="20%">1924</td>
                        </tr>
                        <tr>
                          <td width="20%">2023-24</td>
                          <td width="20%">3.96 Cr</td>
                          <td width="20%">1988</td>
                        </tr>
                        <tr>
                          <td width="20%" className="Bold16">
                            Total
                          </td>
                          <td width="20%" className="Bold16">
                            14.18 Cr
                          </td>
                          <td width="20%" className="Bold16">
                            11608
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div id="accordion" class="accordion desktopShow mobileShow">
                <div class="card mb-0">
                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    href="#collapseOne"
                  >
                    <a class="card-title">2013</a>
                  </div>
                  <div
                    id="collapseOne"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2013</h3>
                    <p>
                      In Jul 2013 Cope with Cancer website was initiated and
                      later in August Cope with Cancer website soft launched
                      with about 20+ pages. In the course of the year Cope with
                      Cancer updated to 120+ pages. More information on
                      resources & related topics for cancer patients and
                      caregivers added. We changed the website from static to
                      dynamic. In September 2013 itself we had 150000 hits on
                      the website - 7000 hits per month
                    </p>
                    <p>
                      In September we tied up with Helpline: iCALL for tele
                      counseling and later with Helpline: Aasra.{" "}
                    </p>
                    <p>
                      Nov 2013 we tied up with Midtown Parel for concessional
                      radiology investigations for TMH patients
                    </p>
                  </div>
                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseTwo"
                  >
                    <a class="card-title">2014</a>
                  </div>
                  <div
                    id="collapseTwo"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2014</h3>
                    <p>
                      At the rate of 9000 hits per month we had over 100,000
                      visitors assisted through cope with cancer website and by
                      December 350,000 patients and caregivers were assisted
                      through the website
                    </p>
                    <p>
                      2014 saw us run a number of awareness drives. Hair
                      donation awareness program was launched, we participated
                      in Lavasa Car Rally. We associated with Red FM Cochin for
                      hair donation drive in Kerala and subsequently a Hair
                      donation awareness program along with the Tuning Folks. We
                      tied up with R K wigs Pankaj Bhupatkar for wigs.
                    </p>
                    <p>
                      We initiated discounted mammography with Suburban
                      Diagnostics, Jankharia Imaging, Dr Aman Daftary’s Clinic,
                      Dr Ambrish Dalal’s clinic & Dr Gala’s clinic. Discounted
                      prosthesis and services offered on CopewithCancer website
                    </p>
                    <p>
                      Counselors Training program was launched in September
                      2014, where Dr. Nagesh Simha, a specialist in palliative
                      care gave a lecture on Psychosocial and spiritual issues
                      in palliative care- at TISS to the students of psychiatry.
                    </p>
                    <p>
                      In September, we started disbursing funds for
                      investigations and treatment for patients at Tata Hospital
                      Mumbai through MSW department.
                    </p>
                  </div>
                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseThree"
                  >
                    <a class="card-title">2015</a>
                  </div>
                  <div
                    id="collapseThree"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2015</h3>
                    <p>
                      In January we started disbursing free wigs to women
                      patients in Tata Hospital parel. In March we had a wig
                      donation program in TMH & felicitation of Madat Trust for
                      activities done at TMH. Again we partnered with Red FM
                      Cochin hair donation drive in Kerala
                    </p>
                    <p>
                      In June cope with cancer partnered with Association of
                      Medical Consultants for spreading awareness about cancer.
                      Superb performance by Sivamani and Runa Sivamani to
                      celebrate Doctors Day at In Orbit mall Malad.{" "}
                    </p>
                    <p>
                      In July educational & financial aid given to cancer
                      victors’ (computer and funds)
                    </p>
                    <p>
                      In July as a part of Doctors Day program, we presented the
                      activities the association with AMC for the year 2015 -
                      16.
                    </p>
                    <p>
                      In September Helpdesk was started in TMH Golden Jubilee
                      Building with volunteers to assist patients
                    </p>
                    <p>
                      By December 700,000 cancer patients and caregivers were
                      benefited through our online and telephonic counseling
                      service and our website
                    </p>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseFour"
                  >
                    <a class="card-title">2016</a>
                  </div>
                  <div
                    id="collapseFour"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2016</h3>
                    <p>
                      In January we started Helpdesk, wig donation, financial
                      assistance and post op counseling in ACTREC Tata,
                      Kharghar. We also attended counseling workshop organized
                      at Actrec
                    </p>
                    <p>
                      Awareness program for cope with cancer with the Tuning
                      Folks was organised
                    </p>
                    <p>
                      In March we started post operation counseling sessions
                      with breast OPD
                    </p>
                    <p>
                      In March we also participated in Hair donation drive and
                      awareness program at Jaslok hospital on the occasion of
                      Women’s Day
                    </p>
                    <p>
                      In April we started Pediatric ward counseling sessions and
                      student volunteers spent time with patients in pediatric
                      ward during their annual vacation. The volunteers started
                      manning the genera Helpline & Helpdesk at Tata Hospital
                      Parel
                    </p>
                    <p>
                      Come August the information on the cope with cancer
                      website was re-organised and updated. The site has been
                      spruced up with a with a new look and made mobile
                      friendly.
                    </p>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseFive"
                  >
                    <a class="card-title">2017</a>
                  </div>
                  <div
                    id="collapseFive"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2017</h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, weekly Pediatric ward counseling sessions
                        for pediatric ward patients and caregivers in TMH
                        continues with a very positive feedback from the
                        patients, caregivers and organizers.
                      </li>
                      <li>
                        Volunteers continue to hold counseling & physiotherapy
                        sessions for post-operative patients at breast OPD in
                        Tata Memorial Hospital and Actrec
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>

                      <li>
                        April 2017, volunteers attended CME workshop organized
                        at Sir H N Reliance Foundation Hospital to raise
                        awareness about physical as well as psychological
                        rehabilitation of the patients who are on cancer therapy
                        was discussed. One of the most significant part of
                        cancer therapy is Rehabilitation of the patient back to
                        his / her physical and mental status as it was pre
                        therapy.
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                    </ul>

                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 70%,
                        radiotherapy 14%, surgery 4%, & ICU support 12%
                      </li>
                      <li>
                        Financial assistance given to patients for discounted
                        radiotherapy to be done at Hiranandani Hospital.
                      </li>
                    </ul>
                    <p className="Bold16">Discounted Investigations</p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiated terms with various institutions to provide
                        discounted Radiotherapy and with onco-surgeons for
                        economically challenged patients outside Tata Hospital
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Donate about 50 wigs per month at TMH and online
                        requests
                      </li>
                    </ul>
                    <p className="Bold16">Patient comfort through Ola Cabs</p>
                    <ul className="listContentRegular">
                      <li>
                        Ola Cabs continue to provide 20% discount to Tata
                        Hospital patients to travel in comfortable & hygienic
                        environment since April 2017. More than 600 patients and
                        caregivers per month avail of this facility
                      </li>
                    </ul>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseSix"
                  >
                    <a class="card-title">2018</a>
                  </div>
                  <div
                    id="collapseSix"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2018</h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, weekly Pediatric ward counseling sessions
                        for pediatric ward patients and caregivers in TMH
                        continues with a very positive feedback from the
                        patients, caregivers and organizers.
                      </li>
                      <li>
                        Volunteers continue to hold counseling & physiotherapy
                        sessions for post-operative patients at breast OPD in
                        Tata Memorial Hospital and Actrec
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                      <li>
                        April 2017, volunteers attended CME workshop organized
                        at Sir H N Reliance Foundation Hospital to raise
                        awareness about physical as well as psychological
                        rehabilitation of the patients who are on cancer therapy
                        was discussed. While most doctors are focusing on
                        treatment of cancer and improving survival for patients,
                        there are a few other factors which are important for
                        patients, which do not seem to get focused on often. One
                        of the most significant part of cancer therapy is
                        Rehabilitation of the patient back to his / her physical
                        and mental status as it was pre therapy
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a className="urlLinkRgular" href="/">
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        August 2016 CWC website updated with more information
                        and a new look
                      </li>
                      <li>
                        Blogs, articles and information added on the website.
                      </li>
                    </ul>
                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 70%,
                        radiotherapy 14%, surgery 4%, & ICU support 12%
                      </li>
                      <li>
                        Financial assistance given to patients for discounted
                        radiotherapy to be done at Hiranandani Hospital.
                      </li>
                    </ul>
                    <p className="Bold16">Discounted Investigations</p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiating terms with various institutions to provide
                        discounted Radiotherapy for patients outside Tata
                        Hospital
                      </li>
                      <li>
                        Negotiating terms with various onco-surgeons to provide
                        discounted surgeries for economically challenged
                        patients.
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Tied up with another wigmaker as the demand for wigs is
                        constantly increasing.
                      </li>
                      <li>
                        Donate about 50 wigs per month at TMH and online
                        requests
                      </li>
                    </ul>
                    <p className="Bold16">Awareness Programs</p>
                    <ul className="listContentRegular">
                      <li>
                        April 2017, The Tuning Folks once again dedicated a
                        musical evening to Madat trust for raising awareness
                        about cancer care.
                      </li>
                      <li>
                        September 2017 Project 6Kx6K launched to make more
                        people aware and involved in cancer care.
                      </li>
                      <li>
                        October 2017 Aapollo Multimedia supported Madat Trust
                        activities to bring about an awareness through a musical
                        program.
                      </li>
                      <li>
                        November 2017, Sargam, The Musical Medicos, supported
                        Madat Trust activities to bring about an awareness
                        through a musical program.
                      </li>
                      <li>
                        December 2017, photo booth organized at Christmas Party
                        for children at TMH. Small attempt to make sure that
                        they took with them some pleasant memories of Tata as
                        well. More than 200 children with their caregivers were
                        given a photograph clicked with props.
                      </li>
                      <li>
                        February 2018 Aapollo Multimedia supported Madat Trust
                        activities to bring about an awareness through a musical
                        program. A brief AV of Madat was shown to the audience.
                      </li>
                    </ul>
                    <p className="Bold16">Patient comfort through Ola Cabs</p>
                    <p>
                      Ola Cabs continue to provide 20% discount to Tata Hospital
                      patients to travel in comfortable & hygienic environment
                      since April 2017. More than 600 patients and caregivers
                      per month avail of this facility.
                    </p>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers{" "}
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers continue to access the
                        counseling helplines iCALL & Aasra for psycho social
                        solutions to their problems.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseSeven"
                  >
                    <a class="card-title">2019</a>
                  </div>
                  <div
                    id="collapseSeven"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">
                      <b>2019</b>
                    </h3>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        Weekly post-operative counseling & physiotherapy
                        sessions for breast OPD patients in TMH continue with
                        very positive feedback from the patients & caregivers in
                        Tata Memorial Hospital Parel and Actrec
                      </li>
                      <li>
                        Volunteers continue to hold counseling sessions for
                        pediatric, gynec & thoracic ward patients
                      </li>
                      <li>
                        Volunteers continue to accompany and explain the
                        patients with language barrier to understand the doctor
                        in the OPD
                      </li>
                      <li>
                        Madat contributes at the main Helpline at Tata Memorial
                        compound. Since April 2016 the volunteers started
                        manning the general Helpline
                      </li>
                    </ul>
                    <ul className="noFlexlistContent">
                      <li>
                        The volunteers guide, navigate and assist the patients,
                        caregivers and other organizations
                      </li>
                      <li>Help in serving food at lunch time</li>
                      <li>Guide the donors & Distribute donations</li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1593148384282000&usg=AFQjCNEF03QUHH1ywHhKte2W5rJKVzI7kA"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        CWC website updated with more information and a new look
                      </li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website
                      </li>
                    </ul>
                    <p className="Bold16">Financial Assistance to patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec.
                        Average proportion spent is chemotherapy 30%,
                        radiotherapy 30%, surgery 22%, Investigations 8% &
                        supportive care 10%
                      </li>
                      <li>
                        Financial assistance continues for patients at Homi
                        Bhabha Cancer Hospital Varanasi
                      </li>
                      <li>
                        Financial assistance given to the economically
                        challenged patients for discounted chemotherapy,
                        radiotherapy & surgery to be done at Dr L H Hiranandani
                        Hospital, H N Reliance Hospital, MPCT Hospital, Asian
                        cancer Institute, Masina Hospital, DNB Onco care, Holy
                        spirit Hospital etc
                      </li>
                      <li>
                        Tied up with a group of onco-surgeons called Mumbai
                        Cancer Care - MCC who provide low cost packages for
                        surgery. Madat Trust partially funds the needy patients.
                      </li>
                      <li>
                        Negotiating terms with various Onco-Surgeons to provide
                        discounted surgeries for economically challenged
                        patients.
                      </li>
                      <li>
                        Financial assistance with subsidy offered to TMH
                        patients for diagnostic tests, the patients paying only
                        what they would have paid at TMH
                      </li>
                    </ul>
                    <p className="Bold16">
                      Discounted Investigations / treatment
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Discounted radiology investigations & nuclear scans
                        being provided to patients through CWC website. Adding
                        more service providers for better rates and convenience.
                      </li>
                      <li>
                        Negotiating terms with various institutions to provide
                        discounted Radiation therapy for patients outside Tata
                        Hospital
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Donate appx 50 wigs per month to patients undergoing
                        chemotherapy at Tata Hospital Mumbai, CMC Vellore, CIHSR
                        Hospital at Dimapur Nagaland, Padhar Hospital Betul MP
                        and to online requests from all over India.
                      </li>
                      <li>
                        Hair donations received from individual donors,
                        institutions & parlours from all over the country &
                        abroad. More than 10000 men, women & children have
                        donated as yet.
                      </li>
                      <li>
                        February 2020, participated in Hairey’s Angel, the hair
                        donation drive organised by Jaslok Hospital at kala
                        Ghoda festival
                      </li>
                    </ul>
                    <p className="Bold16">
                      Awareness Programs
                      <br />
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Promotion of Project 6Kx6K continues through various
                        social media like Facebook, Twitter, Instagram etc to
                        make more people aware and involved in cancer care.
                      </li>
                      <li>
                        April 2019 Dr Anand Parihar participated in a panel
                        discussion organised by Sanjeevani Trust for
                        psycho-social support in cancer care which was attended
                        by 700+ cancer patients, caregivers & guests.
                      </li>
                      <li>
                        May, August, November 2019 Aadvita Multimedia supported
                        Madat Trust activities to bring about awareness through
                        a musical program.
                      </li>
                      <li>
                        August 2019, The Tuning Folks once again dedicated a
                        musical evening to Madat trust for raising awareness
                        about cancer care.
                      </li>
                      <li>
                        In September, volunteers attended the first of the three
                        module organizational development program “Saksham”
                        organised by S P Jain Institute of Management & Research
                        through their Centre for Education in the Social Sector
                        (CEdSS). This unique program was for patient support
                        groups & patient advocates to bring sustainable impact
                        towards patient centric care & to understand the
                        increasing need of professionalism and sound process
                        orientation required to manage and lead organizations.
                      </li>
                      <li>
                        October 2019, formed a patient representative committee
                        at Actrec with the directors & NGOs for patient benefit
                      </li>
                      <li>
                        December 2019 participated in NGO workshop and the panel
                        discussion at Lung Conference
                      </li>
                      <li>
                        December 2019 attended the launch program of the Three
                        Months Certification Course in Palliative Care at
                        Mumbai. Course director Dr Naveen Salins (Professor and
                        Head of the Department, Palliative Medicine and
                        Supportive Care, Kasturba Hospital).
                      </li>
                      <li>
                        December 2019, tied up with Happydemics, the company
                        founded by the singer Shaan to promote upcoming talent.
                        Every month one singer would perform at Actrec for
                        patients, caregivers & doctors
                      </li>
                    </ul>
                    <ul className="listContentRegular">
                      <li>
                        January 2020, attended first Testicular Cancer Survivors
                        meet at Tata Memorial Hospital in association with
                        Gunvati Jagan Nath Kapoor Foundation for understanding
                        testicular patients’ counseling
                      </li>
                      <li>
                        Feb 2020 Aadvita Multimedia once again supported Madat
                        Trust activities to bring about awareness through a
                        musical program.
                      </li>
                      <li>
                        In March 2020, begun a campaign ShootIt - demonstrating
                        the method of breast self examination on social media
                        sites. We requested the relevant doctors to make a one
                        minute clip informing the viewers about the importance
                        of BSE.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers call our counsellors for
                        psycho social solutions to their problems
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseEight"
                  >
                    <a class="card-title">2020</a>
                  </div>
                  <div
                    id="collapseEight"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2020</h3>
                    <p className="Bold16">
                      During the current Covid19 pandemic, Madat Charitable
                      Trust has contributed to the cause of humanity and has
                      donned the role of the 'corona warrior' since March 2020.
                    </p>
                    <p className="Bold16">Financial Assistance to patients-</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continued at TMH and Actrec.
                        Average proportion spent is chemotherapy 30%, radiation
                        therapy 30%, surgery 22%, Investigations 8% & supportive
                        care 10%
                      </li>
                      <li>
                        Financial assistance continued for patients at Homi
                        Bhabha Cancer Hospital Varanasi
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy.
                      </li>
                      <li>
                        Financial assistance given to the economically
                        challenged patients for discounted chemotherapy,
                        radiotherapy & surgery to be done at Dr L H Hiranandani
                        Hospital, H N Reliance Hospital, Reliance Hospital Navi
                        Mumbai, Masina Hospital, Conwest Hospital, HCG ICS
                        Hospital, Super Specialty Surgery Cancer hospital, Holy
                        spirit Hospital etc
                      </li>
                    </ul>
                    <p className="Bold16">Patient Assistance in TMH-</p>
                    <ul className="listContentRegular">
                      <li>
                        From March 2020 copewithcancer-Madat Trust helpdesk was
                        un-operational & the volunteer activities at TMH were
                        not possible.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website
                      </li>
                      <li>CWC website updated with more information</li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website.
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        From March-Dec 2020, we had stopped accepting hair
                        donations.{" "}
                      </li>
                      <li>
                        Number of wigs donated had stopped from March –Dec 2020.{" "}
                      </li>
                    </ul>
                    <p className="Bold16">Awareness Programs</p>
                    <ul className="listContentRegular">
                      <li>
                        Relevant talks by oncologist & onco surgeons from TMH
                        were shared on copewithcancer Facebook page
                      </li>
                      <li>
                        Promotion of Project 6Kx6K continues through various
                        social media to make more people aware and involved in
                        cancer care.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling To Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers access the website for{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        E-counseling & connect with our counselors for
                        tele-counseling for non-medical, psycho-social solutions
                        to their problems.
                      </li>
                      <li>
                        Our counselors provide free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar
                      </li>
                    </ul>
                    <p className="Bold16">
                      Dietary Suggestions & Recipes By Cookery Experts
                    </p>
                    <ul className="listContentRegular">
                      <li>Ongoing updates</li>
                    </ul>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseNine"
                  >
                    <a class="card-title">2021</a>
                  </div>
                  <div
                    id="collapseNine"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2021</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                        <a name="m_-8658947324293647620__Hlk102838162"></a>
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam & Malabar
                        Cancer Centre in Kerala & in Mumbai at Dr LH Hiranandani
                        Hospital, HN Reliance Hospital, Reliance Hospital Navi
                        Mumbai, Masina Hospital, Saboo Siddiqui Hospital, Ved
                        Hospital, SRV Hospital, Ashirwad Hospital HCG group of
                        Hospitals, Super Specialty Surgery Cancer, MOC Hospital,
                        Oncura Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Proportion of spend in 2021-22
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">38%</td>
                          <td width="161">70%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">18%</td>
                          <td width="161">6%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0</td>
                          <td width="161">21%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">44%</td>
                          <td width="161">3%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 10,000</td>
                          <td width="161">₹ 27,000</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="Bold16">Patient Assistance in TMH</p>
                    <ul className="listContentRegular">
                      <li>
                        From March 2020 due to the Covid19 pandemic, layout
                        changes & lack of table space, all the helpdesks in
                        general OPD area at TMH are non-operational.
                      </li>
                      <li>
                        {" "}
                        Post-operative counseling & physiotherapy sessions for
                        breast OPD patients in TMH continue with a very positive
                        feedback from the patients & caregivers in Tata Memorial
                        Hospital Parel.
                      </li>
                      <li>
                        Volunteers continue to counsel thoracic ward &
                        palliative patients one on one
                      </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Restarted accepting hair donation from January 2021.Hair
                        donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10396 men, women & children have
                        donated hair this year. Hair donations received from
                        individual donors, institutions & parlours from all over
                        the country & abroad
                      </li>
                      <li>
                        Restarted couriering wigs to patients undergoing
                        chemotherapy at Tata Hospital Mumbai and to online
                        requests from all over India. 180 wigs were donated to
                        women patients undergoing chemotherapy at Tata Hospital
                        Mumbai. 174 wigs were couriered to women patients
                        undergoing chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website
                      </li>
                      <li>CWC website updated with more information</li>
                      <li>
                        Blogs, articles and information added periodically on
                        the website.
                      </li>
                    </ul>
                    <div>
                      <p className="Bold16">
                        Counseling - Online & Telephonic Counseling to Patients
                        & Caregivers
                      </p>
                      <ul className="listContentRegular">
                        <li>
                          Cancer patients and caregivers accessed the website{" "}
                          <a
                            className="urlLinkRgular"
                            href="/"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                          >
                            copewithcancer.org
                          </a>{" "}
                          for E-counseling & connect with our counselors for
                          tele-counseling for non-medical, psycho-social
                          solutions to their problems.
                        </li>
                        <li>
                          Our counselors provided free psycho-social tele
                          counseling to breast, pediatric, gynec & thoracic ward
                          patients of Tata Hospital Mumbai & Actrec Kharghar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseTen"
                  >
                    <a class="card-title">2022</a>
                  </div>
                  <div
                    id="collapseTen"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2022</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai F at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam, Deenanath
                        Mangeshkar Hospital Pune, Sane Guruji Rugnalaya Nashik &
                        Malabar Cancer Centre in Kerala & in Mumbai at Dr LH
                        Hiranandani Hospital, HN Reliance Hospital, Reliance
                        Hospital Navi Mumbai, SRV Hospital, HCG group of
                        Hospitals, Super Specialty Surgery Cancer, Oncura
                        Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Donation in 2022-23
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228" className="Bold16">
                            Total Amount Donated
                            <br />
                            <span className="Bold16">Rs 354,56,870</span>
                          </td>

                          <td width="198" className="Bold16">
                            ₹ 151,96,000
                          </td>
                          <td width="161" className="Bold16">
                            ₹ 202,60,870
                          </td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">33.8%</td>
                          <td width="161">59.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">32.6%</td>
                          <td width="161">8.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0.1%</td>
                          <td width="161">26.4%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">33.5%</td>
                          <td width="161">4.8%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 12032</td>
                          <td width="161">₹ 30652</td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10158 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        174 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        177 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic
                    </p>
                  </div>
                  {/* 2023 */}
                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseEleven"
                  >
                    <a class="card-title">2023</a>
                  </div>
                  <div
                    id="collapseEleven"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2023</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy & radiation therapy. Financial assistance
                        given at Cacher Cancer Hospital in Assam, Deenanath
                        Mangeshkar Hospital Pune, Sane Guruji Rugnalaya Nashik &
                        Malabar Cancer Centre in Kerala & in Mumbai at Dr LH
                        Hiranandani Hospital, HN Reliance Hospital, Reliance
                        Hospital Navi Mumbai, SRV Hospital, HCG group of
                        Hospitals, Super Specialty Surgery Cancer, Oncura
                        Hematology Centre etc.
                      </li>
                    </ul>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Donation in 2022-23
                          </th>
                          <th scope="col" className="Bold16">
                            TMH & Affiliated Hospitals
                          </th>
                          <th scope="col" className="Bold16">
                            Private Hospitals
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228" className="Bold16">
                            Total Amount Donated
                            <br />
                            <span className="Bold16">Rs 354,56,870</span>
                          </td>

                          <td width="198" className="Bold16">
                            ₹ 151,96,000
                          </td>
                          <td width="161" className="Bold16">
                            ₹ 202,60,870
                          </td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">33.8%</td>
                          <td width="161">59.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Chemotherapy</td>
                          <td width="198">32.6%</td>
                          <td width="161">8.9%</td>
                        </tr>
                        <tr>
                          <td width="228">Radiation therapy</td>
                          <td width="198">0.1%</td>
                          <td width="161">26.4%</td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & supportive care</td>
                          <td width="198">33.5%</td>
                          <td width="161">4.8%</td>
                        </tr>
                        <tr>
                          <td width="228">Average spend per patient</td>
                          <td width="198">₹ 12032</td>
                          <td width="161">₹ 30652</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Patient Assistance in TMH - Activities suspended post
                      Covid pandemic
                    </p>
                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 10158 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        174 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        177 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic Number of patients impacted & the
                      amount spent since inception.
                    </p>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16">
                            Year
                          </th>
                          <th scope="col" className="Bold16">
                            No. of pts
                          </th>
                          <th scope="col" className="Bold16">
                            Amt Spent in Lac ₹
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">2014-15</td>
                          <td width="20%">286</td>
                          <td width="20%">10 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2015-16</td>
                          <td width="20%">660</td>
                          <td width="20%">23.4 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2016-17</td>
                          <td width="20%">778</td>
                          <td width="20%">26.7 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2017-18</td>
                          <td width="20%">850</td>
                          <td width="20%">29.5 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2018-19</td>
                          <td width="20%">1292</td>
                          <td width="20%">71 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2019-20</td>
                          <td width="20%">1782</td>
                          <td width="20%">105.8 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2020-21</td>
                          <td width="20%">582</td>
                          <td width="20%">140.2 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2021-22</td>
                          <td width="20%">1466</td>
                          <td width="20%">260.5 L</td>
                        </tr>
                        <tr>
                          <td width="20%">2022-23</td>
                          <td width="20%">1924</td>
                          <td width="20%">354.5 L</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* 2024 */}
                  <div
                    class="card-header cardHeader collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapseTwelve"
                  >
                    <a class="card-title">2024</a>
                  </div>
                  <div
                    id="collapseTwelve"
                    class="card-body collapse"
                    data-parent="#accordion"
                  >
                    <h3 className="BoldColor30">2024</h3>
                    <p className="Bold16">Financial Assistance to Patients</p>
                    <ul className="listContentRegular">
                      <li>
                        Financial assistance continues at TMH and Actrec in
                        Mumbai & at TMH affiliated hospitals Homi Bhabha Cancer
                        Hospital Varanasi & Homi Bhabha Cancer Hospital Vizag.
                      </li>
                      <li>
                        Madat Trust partially funds the economically challenged
                        patients through various oncologist & onco-surgeons /
                        hospitals who provide low cost packages for surgery,
                        chemotherapy, radiation therapy, investigations &
                        supportive care. Financial assistance given at Cacher
                        Cancer Hospital in Assam, Deenanath Mangeshkar Hospital
                        Pune, Shri Guruji Rugnalaya Nashik & Malabar Cancer
                        Centre in Kerala & at HN Reliance Hospital, Dr LH
                        Hiranandani Hospital, Reliance Hospital Navi Mumbai, SRV
                        Hospital, MAH hospital, HCG group of Hospitals,
                        Specialty Surgical Oncology Hospital, Indian Cancer
                        Treatment Centre, Sunrise Oncology, PCCC, Sunrise
                        Oncology Center etc in Mumbai.
                      </li>
                    </ul>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16" colSpan="2">
                            Annual Report FY 2023-2024
                          </th>
                          <th scope="col" className="Bold16">
                            Patients
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="228">Total Amount Donated</td>
                          <td width="198">₹ 3.95 cr</td>
                          <td width="161">1988</td>
                        </tr>
                        <tr>
                          <td width="228">Private Hospitals - 55%</td>
                          <td width="198">₹ 2.16 cr</td>
                          <td width="161">677</td>
                        </tr>
                        <tr>
                          <td width="228">TMH & Affiliated Hospitals 45%</td>
                          <td width="198">₹ 1.79 cr</td>
                          <td width="161">1311</td>
                        </tr>
                        <tr>
                          <td width="228"></td>
                          <td width="198"></td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Surgery</td>
                          <td width="198">48.70%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Chemo therapy</td>
                          <td width="198">19.10%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Radiation Therapy </td>
                          <td width="198">15.10%</td>
                          <td width="161"></td>
                        </tr>
                        <tr>
                          <td width="228">Investigations & Supportive Care</td>
                          <td width="198">17.10%</td>
                          <td width="161"></td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Patient Assistance in TMH - Activities suspended post
                      Covid pandemic
                    </p>
                    <p className="Bold16">
                      From March 2020 due to the Covid19 pandemic, layout
                      changes & lack of table space, helpdesks in general OPD
                      area at TMH are non-operational.
                    </p>
                    <ul className="listContentRegular">
                      <li>Help Desk at Tata Memorial Hospital Mumbai</li>
                      <li>
                        Psycho-social counseling to breast & thoracic ward
                        patients at Tata Hospital Mumbai
                      </li>
                      <li>
                        Post-operative physiotherapy & counseling sessions at
                        Breast & Thoracic OPD at Tata Hospital & Actrec
                      </li>
                      <li>
                        Songs & dance for patients, caregivers & healthcare
                        workers in Tata Hospital & Actrec
                      </li>
                      <li>Bereavement support groups </li>
                    </ul>
                    <p className="Bold16">Hair & wig Donation</p>
                    <ul className="listContentRegular">
                      <li>
                        Hair donations received from individual donors,
                        organizations, institutions & salons from all over the
                        country & abroad. 7289 men, women & children have
                        donated hair this year.
                      </li>
                      <li>
                        192 wigs were donated to patients undergoing
                        chemotherapy at Tata Hospital Mumbai
                      </li>
                      <li>
                        152 wigs were couriered to women patients undergoing
                        chemotherapy all over India.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Breast Cancer Treatment Project - " Karo Janch Na Ayegi
                      Aanch"
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        We are providing free breast cancer screening and
                        discounted investigations and treatment. Mastectomy with
                        axillary clearance is being done at INR 50,000 at
                        various reputed hospitals in Mumbai with the cooperation
                        of senior breast surgeons
                      </li>
                    </ul>
                    <p className="Bold16">
                      Website{" "}
                      <a
                        className="urlLinkRgular"
                        href="/"
                        data-saferedirecturl="https://www.google.com/url?q=http://www.copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1SdRKF5cbhaOYDisUvHbjx"
                      >
                        www.copewithcancer.org
                      </a>{" "}
                      - Nationwide information about the resources needed by
                      cancer patients & information about the disease and its
                      effects
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Every month, more than 15000 patients & caregivers
                        accessed the website.
                      </li>
                      <li>
                        CWC website updated with more information. Blogs,
                        articles and information added periodically on the
                        website.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Counseling - Online & Telephonic Counseling to Patients &
                      Caregivers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        Cancer patients and caregivers accessed the website{" "}
                        <a
                          className="urlLinkRgular"
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-saferedirecturl="https://www.google.com/url?q=http://copewithcancer.org&source=gmail&ust=1689396961385000&usg=AOvVaw1efM69hflrl_Enx16g37Al"
                        >
                          copewithcancer.org
                        </a>{" "}
                        for E-counseling & connect with our counselors for
                        tele-counseling for non-medical psycho-social solutions.
                      </li>
                      <li>
                        Our counselors provided free psycho-social tele
                        counseling to breast, pediatric, gynec & thoracic ward
                        patients of Tata Hospital Mumbai & Actrec Kharghar.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Music Therapy for patients / caregivers / healthcare
                      workers
                    </p>
                    <ul className="listContentRegular">
                      <li>
                        copewithcancer- Madat Trust arranged online monthly
                        music programs with The Shankar Mahadevan Academy (SMA)
                        for patients, caregivers, healthcare workers and
                        doctors. SMA Nirvana’s Tune In Positivity program gives
                        an opportunity to talented patients to join & get
                        trained at SMA academy.
                      </li>
                    </ul>
                    <p className="Bold16">
                      Audiometry test done for 98 TMH patients free of cost at
                      Dr Narurkar’s ENT clinic
                    </p>
                    <table className="table table-bordered table-responsive tableWidth">
                      <thead>
                        <tr>
                          <th scope="col" className="Bold16" colSpan="3">
                            TOTAL NUMBER OF PATIENTS IMPACTED & AMOUNT
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="Bold16">
                            Year
                          </th>
                          <th scope="col" className="Bold16">
                            Amt Spent
                          </th>
                          <th scope="col" className="Bold16">
                            Patients
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">2014-15</td>
                          <td width="20%">10 L</td>
                          <td width="20%">286</td>
                        </tr>
                        <tr>
                          <td width="20%">2015-16</td>
                          <td width="20%">23.4 L</td>
                          <td width="20%">660</td>
                        </tr>
                        <tr>
                          <td width="20%">2016-17</td>
                          <td width="20%">26.7 L</td>
                          <td width="20%">778</td>
                        </tr>
                        <tr>
                          <td width="20%">2017-18</td>
                          <td width="20%">29.5 L</td>
                          <td width="20%">850</td>
                        </tr>
                        <tr>
                          <td width="20%">2018-19</td>
                          <td width="20%">71 L</td>
                          <td width="20%">1292</td>
                        </tr>
                        <tr>
                          <td width="20%">2019-20</td>
                          <td width="20%">1.06 Cr</td>
                          <td width="20%">1782</td>
                        </tr>
                        <tr>
                          <td width="20%">2020-21</td>
                          <td width="20%">1.40 Cr</td>
                          <td width="20%">582</td>
                        </tr>
                        <tr>
                          <td width="20%">2021-22</td>
                          <td width="20%">2.61 Cr</td>
                          <td width="20%">1466</td>
                        </tr>
                        <tr>
                          <td width="20%">2022-23</td>
                          <td width="20%">3.55 Cr</td>
                          <td width="20%">1924</td>
                        </tr>
                        <tr>
                          <td width="20%">2023-24</td>
                          <td width="20%">3.96 Cr</td>
                          <td width="20%">1988</td>
                        </tr>
                        <tr>
                          <td width="20%" className="Bold16">
                            Total
                          </td>
                          <td width="20%">14.18 Cr</td>
                          <td width="20%">11608</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historyinabout;
