import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigation, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import CommonInputs from "./CommonInputs";

const CtScanForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full name a required field")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email Id a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    contact: Yup.string()
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      )
      .required("Contact number is a required field"),
    state: Yup.string().required("State is a required field"),
    city: Yup.string().required("City is a required field"),
    locality: Yup.string().required("Locality is a required field"),
    zip_code: Yup.string().required("Postal or Zip Code is a required field").matches(
      /^\d{6}$/,
      "Postal code should be a 6-digit number"
    ),
    treatmentDetails: Yup.string().required("Treatment Detail is required"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.title = "Register for CT scan";
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          contact: "",
          country: "India",
          state: "",
          city: "",
          locality: "",
          zip_code: "",
          treatmentDetails: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mb15">
            <div className="row">
              <div className="col-md-12">
                <div id="midleft1">
                  <div className="Bold28 mb20">Register for CT Scan</div>
                  {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
                </div>
                <div className="mb15">
                  Fields marked with an <span className="requiredColor">*</span>{" "}
                  are required
                </div>
                <CommonInputs />
                <div class="form-group">
                  <label className="required">
                    Details of Treatment Recommended
                  </label>
                  <Field
                    type="text"
                    name="treatmentDetails"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="treatmentDetails"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <button
                  class="btn btn-primary my-2 my-sm-0 Allbtn "
                  type="submit"
                  disabled={isSubmitting}
                >
                  {navigation.state === "submitting"
                    ? "Processing..."
                    : navigation.state === "loading"
                    ? "Saved!"
                    : "Submit"}
                </button>
              </div>

              {/* <div className="col-md-6">
                <div className="contactInfoBox">
                  For All Queries, Write To Us
                </div>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CtScanForm;
